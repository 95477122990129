/* Copyright 2020 Avetti.com Corporation - All Rights Reserved

This source file is subject to the Avetti Commerce Front End License (ACFEL 1.20)
that is accessible at https://www.avetticommerce.com/license */
import React, {useEffect, useState, useRef, useLayoutEffect} from "react";
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import "../../assets/css/product-sl.css";
import "./Styles/OFBProductPage.css";

import ImageCarousel from "./Components/ImageCarousel/BDImageCarousel";
import ReviewBar from "./Components/Review/ReviewBar";
import ReviewBarMobile from "./Components/Review/ReviewBarMobile";
import Attributes from "./Components/Attributes/BDAttributes";
import AddToCartBox from "./Components/AddToCart/AddToCartBox";
import AddedToCartModal from "./Components/AddToCart/Components/AddedToCartModal";
import Reviews from "./Components/Review/components/Reviews";

import htmldecoder, {htmlDecode} from "../../functions/htmldecoder";
import CheckBoxAddons from "./Components/CheckBoxAddons/CheckBoxAddons";
import ShareButtons from "./Components/ShareButtons/ShareButtons";
import ShareIcon from "@material-ui/icons/Share";
import WishListBar from "./Components/WishList/WishListBar";
import ItemCode from "./Components/ItemCode/ItemCode";
import OtherInfoTab from "./Components/OtherInfoTab/OtherInfoTab";

import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";

import Breadcrumb from "./Components/Breadcrumb/Breadcrumb";
import {I18nContext} from "../../i18n/index";
import {navigate, useLocation} from "@reach/router";
import {
    fetchDirectUrlGetItem,
    getSupplierInfo,
    mapProductUrls
} from "../../redux/actions/productAction";
import ConfirmDeliveryOptions
    from "../AC-ProductPage/Components/AddToCart/Components/ConfirmDeliveryOptions/ConfirmDeliveryOptions";
import CartValidationErrors from "../AC-ProductPage/Components/CartValidationErrors/CartValidationErrors";
import OutOfStockError from "../AC-ProductPage/Components/CartValidationErrors/OutOfStockError";

import fbLogo from "../../assets/img/sellerFB.png";
import twLogo from "../../assets/img/sellerTW.png";
import igLogo from "../../assets/img/sellerIG.png";
import ptLogo from "../../assets/img/sellerPT.png";
import {setGoBackToCategoryFromProductPage} from "../../redux/actions/categoryActions";
import {isArray} from "../../functions/Utilities";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

const OFBProductPage = ({data, storeInfo}) => {
    const {translate, langCode} = React.useContext(I18nContext);
    const location = useLocation();

    const dispatch = useDispatch();
    const priceState = useSelector(
        state => state.productReducer.priceInventory,
        shallowEqual
    );

    const productUnavailableState = useSelector(
        state => state.productReducer.productUnavilable,
        shallowEqual
    );

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "share-popover" : undefined;

    const isMobileState = useSelector(
        state => state.mainReducer.isMobile,
        shallowEqual
    );

    const fetchProduct = useSelector(state => state.productReducer, shallowEqual);
    const {error} = fetchProduct;

    const [swipeLoc, setSwipeLoc] = React.useState({start: 0, end: 0});

    const reviewsContainerRef = useRef();

    try {
        let price = data.priceInvData._xResult[0].prices[0].price_1;
    } catch (error) {
        console.error(
            "*************ERROR PRODUCT PRICE DOES NOT EXIST*************",
            data._0.id
        );
    }

    const [count, setCount] = useState(0);

    const [starFilter, setStarFilter] = useState([]);
    const [avgRating, setAvgRating] = useState({
        avgRating: 0,
        countOfEachStar: {},
        totalValue: 0
    });

    const [reviewsWithImages, setReviewsWithImages] = useState([]);

    useEffect(() => {
        if (typeof window !== undefined) window.scrollTo(0, 0);

        dispatch(getSupplierInfo(data._0.id));
        dispatch(setGoBackToCategoryFromProductPage(true));

        return () => {
            dispatch(setGoBackToCategoryFromProductPage(false));
        };
    }, []);

    /* useLayoutEffect(() => {
      console.info("PRODINDEX 222", data._0.breadcrumbs);
      const { left, right } = mapProductUrls(
        location.pathname,
        data._0.breadcrumbs[data._0.breadcrumbs.length - 1].url.replace(
          "shop/",
          ""
        )
      );
      if (typeof window !== undefined) {
        let start = 0;
        let end = 0;
        let startY = 0;
        let endY = 0;
        let touchTime = 0;
        let diff = 0;

        const touchStartEvent = e => {
          console.error("HELLO 1");
          if (e.changedTouches[0]) {
            start = e.changedTouches[0].screenX;
            startY = e.changedTouches[0].screenY;
            touchTime = new Date();
          }
        };

        const touchEndEvent = e => {
          console.error("HELLO 2");
          if (e.changedTouches[0]) {
            end = e.changedTouches[0].screenX;
            endY = e.changedTouches[0].screenY;
            diff = new Date() - touchTime;
            handleSwiper();
          }
        };

        const handleSwiper = () => {
          if (Math.abs(end - start) > Math.abs(endY - startY) && diff > 100) {
            if (end < start) {
              navigate(`/${left}`, { state: { directionCat: "left" } });
            } else if (end > start) {
              navigate(`/${right}`, { state: { directionCat: "right" } });
            }
          }
        };

        window.addEventListener("touchstart", touchStartEvent);

        window.addEventListener("touchend", touchEndEvent);

        return () => {
          window.removeEventListener("touchstart", touchStartEvent);
          window.removeEventListener("touchend", touchEndEvent);
        };
      }
    }, []);
   */
    useEffect(() => {
        if (count == 0 && typeof localStorage !== undefined) {
            setCount(count + 1);
            let languageStorage = localStorage.getItem("language");
            if (!languageStorage) {
                localStorage.setItem("language", langCode);
                languageStorage = "en";
            }
            let countryStorage = localStorage.getItem("country");
            if (!countryStorage) {
                countryStorage = "en";
                localStorage.setItem("country", countryStorage);
            }
            let url = location.pathname;
            if (url[url.length - 1] === "/") url = url.substr(0, url.length - 1);

            if (url.includes(`/${localStorage.getItem("language")}/`) === false) {
                dispatch(
                    fetchDirectUrlGetItem(
                        url,
                        localStorage.getItem("language"),
                        countryStorage
                    )
                );
            } else {
                setCount(count + 1);
                dispatch(fetchDirectUrlGetItem(url, langCode, countryStorage));
            }
        }

        return () => {
            setCount(0);
        };
    }, [location.pathname, langCode]);

    useEffect(() => {
        var cartBox = document.getElementById("pickupShipInfo");
        cartBox.innerHTML = "";
        var tempPickupHTML = "";
        var tempShipHTML = "";

        console.log("cartBox", cartBox, storeInfo);

        if (storeInfo.pickupLocData && storeInfo.pickupLocData) {
            tempPickupHTML =
                "<div class='pickupShipInfo'><h2>Pickup Locations</h2><div class='pickupShipInfoBody pickup'>";
            if (!isArray(storeInfo.pickupLocData))
                storeInfo.pickupLocData = [{...storeInfo.pickupLocData}];
            for (var x = 0; x < storeInfo.pickupLocData.length; x++) {
                tempPickupHTML =
                    tempPickupHTML +
                    "<p class='pickupDataBox'><strong>" +
                    storeInfo.pickupLocData[x].pickup_location_name +
                    "</strong><br/>" +
                    storeInfo.pickupLocData[x].address_place +
                    " <br/> <a target='_blank' href='https://www.google.com/maps/dir/here/" +
                    storeInfo.pickupLocData[x].latitude +
                    "," +
                    storeInfo.pickupLocData[x].longitude +
                    "'>Get Directions</a>";

                if (storeInfo.pickupLocData[x].time != null) {
                    tempPickupHTML =
                        tempPickupHTML +
                        "<br/><strong>Hours:</strong> " +
                        storeInfo.pickupLocData[x].time;
                }

                if (storeInfo.pickupLocData[x].additional_information != null) {
                    tempPickupHTML =
                        tempPickupHTML +
                        "<br/><strong>Additional Info:</strong> " +
                        storeInfo.pickupLocData[x].additional_information;
                }

                tempPickupHTML = tempPickupHTML + "</p>";
            }

            tempPickupHTML = tempPickupHTML + "</div></div>";
        }

        if (
            storeInfo.supplierShippingInfo &&
            storeInfo.supplierShippingInfo.length > 0 &&
            storeInfo.supplierShippingInfo[0].shipping_type != 0
        ) {
            tempShipHTML =
                "<div class='pickupShipInfo'><h2>Shipping Info</h2><div class='pickupShipInfoBody'>";

            var tempShipHTML = tempShipHTML + "<p><strong>Ships:</strong> ";

            if (storeInfo.supplierShippingInfo[0].shipping_type == 1) {
                tempShipHTML = tempShipHTML + "Worldwide";
            } else if (storeInfo.supplierShippingInfo[0].shipping_type == 2) {
                tempShipHTML = tempShipHTML + "Within Canada";
            } else if (storeInfo.supplierShippingInfo[0].shipping_type == 3) {
                if (storeInfo.storeSellerData.province) {
                    tempShipHTML =
                        tempShipHTML + "Within " + storeInfo.storeSellerData.province;
                } else {
                    tempShipHTML = tempShipHTML + "Within Province";
                }
            } else if (storeInfo.supplierShippingInfo[0].shipping_type == 4) {
                tempShipHTML =
                    tempShipHTML +
                    "Within " +
                    storeInfo.supplierShippingInfo[0].range +
                    "KM";
            }

            tempShipHTML = tempShipHTML + "</p>";

            if (
                storeInfo.supplierShippingInfo[0].instructions &&
                storeInfo.supplierShippingInfo[0].instructions != null
            ) {
                tempShipHTML =
                    tempShipHTML +
                    "<p><strong>Additional Info:</strong> " +
                    storeInfo.supplierShippingInfo[0].instructions +
                    "</p>";
            }

            tempShipHTML = tempShipHTML + "</div></div>";
        }

        cartBox.innerHTML = cartBox.innerHTML + tempPickupHTML;
        cartBox.innerHTML = cartBox.innerHTML + tempShipHTML;
    }, []);

    /*let imageUrl = storeInfo.storeSellerData.image;
    if (imageUrl.includes("sls3.avetti.ca")) {
      imageUrl = imageUrl.replace(
        "sls3.avetti.ca",
        "demob2b2cs3.avetti.ca/store"
      );
    }*/

    var mapsLink;
    if (
        storeInfo.storeSellerData &&
        Object.keys(storeInfo.storeSellerData).includes("location") &&
        storeInfo.storeSellerData.location
    ) {
        mapsLink =
            "https://www.google.ca/maps/search/" + storeInfo.storeSellerData.location;
    } else {
        mapsLink = "";
    }

    const renderStoreInfo = (storeSellerData, section) => {
        if (storeSellerData && storeSellerData[section]) {
            if (isArray(storeSellerData[section])) {
                return storeSellerData[section].map((desc, i) => {
                    return <p dangerouslySetInnerHTML={{__html: desc}}></p>;
                });
            } else return <p>{storeSellerData[section]}</p>;
        }
    };

    return (
        <React.Fragment>
            {/*  <EnquiryModal
        isLoggedIn={loginNameState !== ""}
        enquiryModalState={enquiryModalState}
        setEnquiryModalState={setEnquiryModalState}
      /> */}
            {/* <AccessoryModal /> */}

            <div id="bd" className="item">
                <Breadcrumb back={true}/>
                <div className="col s12">
                    <div style={{marginTop: "5px"}}>
                        <div className="item-main">
                            <div id="mainGridDiv" className="item-top-row row">
                                {isMobileState ? (
                                    <React.Fragment>
                                        <p className="brandItem">
                      <span id="buyBoxDistName">
                        {data &&
                        data.supplierData &&
                        data.supplierData._xResult &&
                        data.supplierData._xResult.length > 0 &&
                        data.supplierData._xResult[0].distributorOrder &&
                        data.supplierData._xResult[0].distributorOrder.length >
                        0 &&
                        data.supplierData._xResult[0].distributorOrder[0].name
                            ? data.supplierData._xResult[0].distributorOrder[0]
                                .name
                            : ""}
                      </span>
                                        </p>

                                        <ReviewBarMobile
                                            avgRating={avgRating}
                                            starFilter={starFilter}
                                            setStarFilter={setStarFilter}
                                            reviewsContainerRef={reviewsContainerRef}
                                        />

                                        <div
                                            id="js-item-title-267578"
                                            className="regularTitle title itemTitle itemTitleMobile"
                                            dangerouslySetInnerHTML={{
                                                __html: htmldecoder(data.productData._xResult[0].title)
                                            }}
                                        ></div>
                                    </React.Fragment>
                                ) : null}

                                <div id="leftGrid">
                                    <ImageCarousel
                                        productUnavailable={productUnavailableState}
                                        giftcard="false"
                                        data={{
                                            productCode: data.productData._xResult[0].code,
                                            itemId: data._0.id,
                                            hiddenProperties:
                                            data.productData._xResult[0].hiddenProperties,
                                            title: data.productData._xResult[0].title,
                                            url: data.url
                                        }}
                                        title={data.productData._xResult[0].title}
                                        imageUrl={`https://preview.open4business.io/preview/store/20180522154/assets/items/images/${data.productData._xResult[0].code}.jpg`}
                                    />
                                    {!isMobileState && (
                                        <ShareButtons
                                            title={data.productData._xResult[0].title}
                                            imageUrl={`https://preview.open4business.io/preview/store/20180522154/assets/items/images/${data.productData._xResult[0].code}.jpg`}
                                        />
                                    )}
                                </div>
                                <div id="rightGrid">
                                    <div id="titleGrid">
                                        {error && <ErrorMessage />}
                                        {!isMobileState ? (
                                            <React.Fragment>
                                                <div
                                                    id="js-item-title-267578"
                                                    className="regularTitle title itemTitle itemTitleMobile"
                                                    dangerouslySetInnerHTML={{
                                                        __html: htmldecoder(
                                                            data.productData._xResult[0].title
                                                        )
                                                    }}
                                                ></div>
                                                <ReviewBar
                                                    avgRating={avgRating}
                                                    starFilter={starFilter}
                                                    setStarFilter={setStarFilter}
                                                    reviewsContainerRef={reviewsContainerRef}
                                                />
                                            </React.Fragment>
                                        ) : null}
                                        {!isMobileState ? (
                                            <React.Fragment>
                                                <WishListBar
                                                    data={data}
                                                    productUnavailable={productUnavailableState}
                                                    price={
                                                        data.priceInvData &&
                                                        Object.keys(data.priceInvData).includes(
                                                            "_xResult"
                                                        ) &&
                                                        data.priceInvData._xResult.length > 0 &&
                                                        Object.keys(data.priceInvData._xResult[0]).includes(
                                                            "prices"
                                                        ) &&
                                                        data.priceInvData._xResult[0].prices.length > 0 &&
                                                        Object.keys(
                                                            data.priceInvData._xResult[0].prices[0]
                                                        ).includes("price_1")
                                                            ? data.priceInvData._xResult[0].prices[0].price_1
                                                            : 0
                                                    }
                                                />
                                            </React.Fragment>
                                        ) : null}
                                        <ItemCode code={data.productData._xResult[0].code}/>
                                        <div className="col-xs-12 product-page-seller-info">
                                            {!isMobileState ? (
                                                <React.Fragment>
                                                    {/* <div className="inStock-container">
                            {priceState && priceState.invs && priceState.invs[0] ? (
                              <div className="inStock-wrapper">
                                <label>{translate("js.item.stock")}:</label>
                                &nbsp;
                                <span style={{ fontWeight: "400" }}>
                                  {priceState.invs[0].instock}
                                </span>
                              </div>
                            ) : null}
                          </div> */}

                                                    <p>
                                                        <strong>{translate("js.item.available")}: </strong>
                                                        <span
                                                            id="buyBoxDistName"
                                                            style={{fontWeight: "400"}}
                                                        >
                              {data &&
                              data.supplierData &&
                              data.supplierData._xResult &&
                              data.supplierData._xResult.length > 0 &&
                              data.supplierData._xResult[0].distributorOrder &&
                              data.supplierData._xResult[0].distributorOrder
                                  .length > 0 &&
                              data.supplierData._xResult[0].distributorOrder[0]
                                  .name
                                  ? data.supplierData._xResult[0]
                                      .distributorOrder[0].name
                                  : ""}
                            </span>
                                                    </p>
                                                </React.Fragment>
                                            ) : null}
                                            <p>
                                                <strong>{translate("js.item.location")}: </strong>
                                                <span id="buyBoxDistLoc" style={{fontWeight: "400"}}>
                          {data &&
                          data.supplierData &&
                          data.supplierData._xResult &&
                          data.supplierData._xResult.length > 0 &&
                          data.supplierData._xResult[0].distributorOrder &&
                          data.supplierData._xResult[0].distributorOrder
                              .length > 0 &&
                          data.supplierData._xResult[0].distributorOrder[0].city
                              ? data.supplierData._xResult[0].distributorOrder[0]
                                  .city
                              : ""}
                        </span>
                                            </p>

                                            <div className="product-page-seller-rating">
                                                <strong>
                                                    {translate("js.item.sellerrating")}:&nbsp;
                                                </strong>
                                                <div id="buyBoxDistRate">
                                                    <div className="dist-item-rating">
                                                        <i className="material-icons star-icon">
                                                            star_border
                                                        </i>
                                                        <i className="material-icons star-icon">
                                                            star_border
                                                        </i>
                                                        <i className="material-icons star-icon">
                                                            star_border
                                                        </i>
                                                        <i className="material-icons star-icon">
                                                            star_border
                                                        </i>
                                                        <i className="material-icons star-icon">
                                                            star_border
                                                        </i>
                                                        <div
                                                            style={{display: "none"}}
                                                            className="distReviewCount"
                                                        >
                                                            0 {translate("js.item.reviews")}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <Attributes data={data}/>

                                        {/*                      <Marketplaces />*/}
                                    </div>
                                    <CheckBoxAddons/>
                                    <AddToCartBox
                                        data={data}
                                        productUnavailable={productUnavailableState}
                                        giftcard="false"
                                        priceInv={data.priceInvData._xResult[0]}
                                        supplierData={
                                            data.supplierData !== null &&
                                            Object.keys(data.supplierData).includes("_xResult") &&
                                            data.supplierData._xResult.length > 0
                                                ? data.supplierData._xResult[0]
                                                : null
                                        }
                                        storeInfo={storeInfo}
                                        title={htmlDecode(data.productData._xResult[0].title)}
                                    />
                                </div>
                                <ConfirmDeliveryOptions/>
                                <CartValidationErrors/>
                                <OutOfStockError/>
                                <AddedToCartModal/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Accessories /> */}
                {/* <VendorList
          handleEnquiryModalOpenClicked={handleEnquiryModalOpenClicked}
        /> */}
                <OtherInfoTab
                    longDesc={{
                        longdesc: data.productData._xResult[0].longdesc,
                        longdesc2: data.productData._xResult[0].longdesc2,
                        longdesc3: data.productData._xResult[0].longdesc3,
                        longdesc4: data.productData._xResult[0].longdesc4
                    }}
                    properties={data.productData._xResult[0].properties}
                />

                <div className="clearfix"></div>
                {storeInfo.storeSellerData && (
                    <div className="storeInfoWrapper itemStoreInfo">
                        <div className="storeLogo">{/*<img src={imageUrl}></img>*/}</div>
                        <div className="storeInfoMain">
                            <div className="storeInfo1">
                                <h1 className="storeName testClass">
                                    {storeInfo.storeSellerData && storeInfo.storeSellerData.brand}
                                </h1>
                                {storeInfo.storeSellerData &&
                                Object.keys(storeInfo.storeSellerData).includes("year") &&
                                storeInfo.storeSellerData.year != "" ? (
                                    <p>
                                        <strong>
                                            Established{" "}
                                            {storeInfo.storeSellerData &&
                                            Object.keys(storeInfo.storeSellerData).includes(
                                                "year"
                                            ) &&
                                            storeInfo.storeSellerData.year}{" "}
                                            -{" "}
                                            {storeInfo.storeSellerData &&
                                            Object.keys(storeInfo.storeSellerData).includes(
                                                "city"
                                            ) &&
                                            storeInfo.storeSellerData.city}
                                            ,{" "}
                                            {storeInfo.storeSellerData &&
                                            Object.keys(storeInfo.storeSellerData).includes(
                                                "province"
                                            ) &&
                                            storeInfo.storeSellerData.province}
                                        </strong>
                                    </p>
                                ) : null}

                                {storeInfo.storeSellerData &&
                                Object.keys(storeInfo.storeSellerData).includes(
                                    "phone_number"
                                ) &&
                                storeInfo.storeSellerData.phone_number != "" ? (
                                    <p>
                                        <strong>Phone:</strong>{" "}
                                        {storeInfo.storeSellerData &&
                                        Object.keys(storeInfo.storeSellerData).includes(
                                            "phone_number"
                                        ) &&
                                        storeInfo.storeSellerData.phone_number}
                                    </p>
                                ) : null}

                                {storeInfo.storeSellerData &&
                                Object.keys(storeInfo.storeSellerData).includes("location") &&
                                storeInfo.storeSellerData.location != "" ? (
                                    <p>
                                        <strong>Address:</strong>{" "}
                                        {storeInfo.storeSellerData &&
                                        Object.keys(storeInfo.storeSellerData).includes(
                                            "location"
                                        ) &&
                                        storeInfo.storeSellerData.location}
                                        <br/>
                                        {mapsLink != "" ? (
                                            <a className="mapsLink" target="_blank" href={mapsLink}>
                                                View on Google Maps
                                            </a>
                                        ) : null}
                                    </p>
                                ) : null}

                                {storeInfo.storeSellerData &&
                                Object.keys(storeInfo.storeSellerData).includes("website") &&
                                storeInfo.storeSellerData.website != null ? (
                                    <p>
                                        <strong>Website:</strong>{" "}
                                        {storeInfo.storeSellerData.website}
                                    </p>
                                ) : null}
                                <p className="sellerSocialLine">
                                    {storeInfo.storeSellerData !== undefined &&
                                    (Object.keys(storeInfo.storeSellerData).includes(
                                        "facebook"
                                        ) ||
                                        Object.keys(storeInfo.storeSellerData).includes(
                                            "twitter"
                                        ) ||
                                        Object.keys(storeInfo.storeSellerData).includes(
                                            "instagram"
                                        ) ||
                                        Object.keys(storeInfo.storeSellerData).includes(
                                            "pinterest"
                                        )) &&
                                    (storeInfo.storeSellerData.facebook != null ||
                                        storeInfo.storeSellerData.twitter != null ||
                                        storeInfo.storeSellerData.instagram != null ||
                                        storeInfo.storeSellerData.pinterest != null) ? (
                                        <span>
                      <strong>Connect With Us:</strong>
                      <br/>
                    </span>
                                    ) : null}

                                    {storeInfo.storeSellerData &&
                                    Object.keys(storeInfo.storeSellerData).includes("facebook") &&
                                    storeInfo.storeSellerData.facebook != null ? (
                                        <a href={storeInfo.storeSellerData.facebook}>
                                            <img className="sellerSocialLogo" src={fbLogo}/>
                                        </a>
                                    ) : null}
                                    {storeInfo.storeSellerData &&
                                    Object.keys(storeInfo.storeSellerData).includes("twitter") &&
                                    storeInfo.storeSellerData.twitter != null ? (
                                        <a href={storeInfo.storeSellerData.twitter}>
                                            <img className="sellerSocialLogo" src={twLogo}/>
                                        </a>
                                    ) : null}
                                    {storeInfo.storeSellerData &&
                                    Object.keys(storeInfo.storeSellerData).includes(
                                        "instagram"
                                    ) &&
                                    storeInfo.storeSellerData.instagram != null ? (
                                        <a href={storeInfo.storeSellerData.instagram}>
                                            <img className="sellerSocialLogo" src={igLogo}/>
                                        </a>
                                    ) : null}
                                    {storeInfo.storeSellerData &&
                                    Object.keys(storeInfo.storeSellerData).includes(
                                        "pinterest"
                                    ) &&
                                    storeInfo.storeSellerData.pinterest != null ? (
                                        <a href={storeInfo.storeSellerData.pinterest}>
                                            <img className="sellerSocialLogo" src={ptLogo}/>
                                        </a>
                                    ) : null}
                                </p>
                            </div>
                            <div className="storeInfo2">
                                <h2 className="storeName">About Us</h2>

                                <div id="aboutInfo">
                                    <p>
                                        {renderStoreInfo(storeInfo.storeSellerData, "values")}
                                        {renderStoreInfo(storeInfo.storeSellerData, "description")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <Reviews
                    avgRating={avgRating}
                    setAvgRating={setAvgRating}
                    starFilter={starFilter}
                    setStarFilter={setStarFilter}
                    reviewsContainerRef={reviewsContainerRef}
                    reviewsWithImages={reviewsWithImages}
                    setReviewsWithImages={setReviewsWithImages}
                />
                {/* <RecentlyViewedItems /> */}
            </div>
        </React.Fragment>
    );
};

export default OFBProductPage;
